import {
  Theme, createTheme, ThemeOptions, PaletteColor, PaletteColorOptions,
} from '@mui/material';
import { darkTheme, lightTheme } from './MaterialUiDefaultTheme';

export function getTheme(dark: boolean): Theme {
  const theme: ThemeOptions = dark ? darkTheme : lightTheme;
  return createTheme({ ...theme });
}

declare module '@mui/material/styles' {
  interface Palette {
    header: PaletteColor
  }
  interface PaletteOptions {
    header: PaletteColorOptions
  }
}

declare module '@mui/material/styles/createTheme' {
  interface Theme {
    compeonLogo: {
      color: React.CSSProperties['color']
    },
    calendarBox: {
      color: React.CSSProperties['color'],
      colorChangeRule: 'darken' | 'lighten'
    }
  }

  interface ThemeOptions {
    compeonLogo: {
      color: React.CSSProperties['color']
    },
    calendarBox: {
      color: React.CSSProperties['color'],
      colorChangeRule: 'darken' | 'lighten'
    }
  }
}
