/**
 * File is used to add configuration values to window variable.
 * Needs to be imported before something wants to use window values.
 */

export type MarketplaceConfig = {
  compeonApiBaseUrl: string
  authConfigBaseUrl: string
  authConfigClientId: string
  loggedInCookieDomain: string
  loggedInCookieName: string
}

declare global {
  interface Window {
    REACT_APP_ENVIRONMENT: string
    REACT_APP_SERVICE_API: string
    REACT_APP_MARKETPLACE_CONFIGSTRING: string
    REACT_APP_MARKETPLACE_CONFIG: MarketplaceConfig,
    isDevelopement: () => boolean,
    isProduction: () => boolean,
    isStaging: () => boolean,
    isTest: () => boolean,
    isProductionLike: () => boolean,
  }
}

window.REACT_APP_ENVIRONMENT = window.REACT_APP_ENVIRONMENT || '';
window.REACT_APP_SERVICE_API = window.REACT_APP_SERVICE_API || '';
window.REACT_APP_MARKETPLACE_CONFIGSTRING = window.REACT_APP_MARKETPLACE_CONFIGSTRING || '';
window.REACT_APP_MARKETPLACE_CONFIG = JSON.parse(window.REACT_APP_MARKETPLACE_CONFIGSTRING);
window.isDevelopement = (): boolean => window.REACT_APP_ENVIRONMENT?.toLocaleLowerCase() === 'development';
window.isProduction = (): boolean => window.REACT_APP_ENVIRONMENT?.toLocaleLowerCase() === 'production';
window.isStaging = (): boolean => window.REACT_APP_ENVIRONMENT?.toLocaleLowerCase() === 'staging';
window.isTest = (): boolean => window.REACT_APP_ENVIRONMENT?.toLocaleLowerCase() === 'test';
window.isProductionLike = (): boolean => window.isProduction() || window.isStaging();
