import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { Theme } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import DrawerHeader from '../DrawerHeader';
import { drawerWidth } from '..';
import { ReactComponent as Logo } from '../../../assets/compeonLogoBlack.svg';
import { getSideMenuEntries } from '../../../pages/routes';
import { SideMenuEntry } from '../../../domain/application';
import SideMenuDrawerListItem from './SideMenuDrawerListItem';
import { RootState } from '../../../redux';

export type SideMenuDrawerProps = {
  sideMenuOpen: boolean,
  onSideMenuClose: () => void,
  theme: Theme
}

const SideMenuDrawer = ({
  sideMenuOpen, onSideMenuClose, theme,
}: SideMenuDrawerProps): JSX.Element => {
  const currentRiskProfileId = useSelector((state: RootState) => state.riskProfile.currentRiskProfile?.id);
  const sideMenuEntries = getSideMenuEntries(currentRiskProfileId);

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="persistent"
      anchor="left"
      open={sideMenuOpen}
    >
      <DrawerHeader>
        <IconButton onClick={onSideMenuClose}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          verticalAlign: 'center',
          p: theme.spacing(),
        }}
      >
        <Logo />
      </Box>
      <Divider />
      <List>
        {
          sideMenuEntries.map((entry: SideMenuEntry) => (
            <SideMenuDrawerListItem
              key={entry.id}
              sideMenuEntry={entry}
            />
          ))
        }
      </List>
    </Drawer>
  );
};

export default SideMenuDrawer;
