import CompeonAPI, { cookieAuthState } from '@compeon-os/api-client';
import { camelCase, isPlainObject } from 'lodash';

const {
  loggedInCookieName, compeonApiBaseUrl, authConfigBaseUrl, authConfigClientId,
  loggedInCookieDomain,
} = window.REACT_APP_MARKETPLACE_CONFIG;// 'compeon-logged-in-staging';

export function deepCamelTransform(data: object): object {
  if (Array.isArray(data)) return data.map((i) => deepCamelTransform(i));
  if (!isPlainObject(data)) return data;

  const result: { [key: string]: object } = {};

  Object.entries(data).forEach((value: [string, object]) => {
    result[camelCase(value[0])] = deepCamelTransform(value[1]);
  });

  return result;
}

export async function removeAuthParams(): Promise<void> {
  const cleanedUrl = new URL(window.location.href);
  const params = new URLSearchParams(cleanedUrl.search.substring(1));

  const paramsDefined = params.get('code') && params.get('state');
  if (!paramsDefined) return;

  params.delete('code');
  params.delete('state');

  cleanedUrl.search = params.toString();

  window.history.replaceState({}, '', cleanedUrl);
}

function assumeLoggedInDev(): void {
  document.cookie = `${loggedInCookieName}=1;domain=${document.location.hostname};`;
}

function assumeLoggedOutDev(): void {
  document.cookie = `${loggedInCookieName}=0;domain=${document.location.hostname};max-age=-1;`;
}

if (window.isDevelopement()) { assumeLoggedInDev(); }

export const client = new CompeonAPI({
  baseURL: compeonApiBaseUrl,
  authConfig: {
    authState: cookieAuthState({ secure: window.isProductionLike(), path: '/' }),
    baseUrl: authConfigBaseUrl,
    clientId: authConfigClientId,
    loggedInCookieDomain,
    loggedInCookieName,
  },
  transformResponse: [deepCamelTransform],
});

export const logout = (): void => {
  if (window.isDevelopement()) { assumeLoggedOutDev(); }
  client.logout();
};

export const waitForClientAuthentication = client.authPromise;
