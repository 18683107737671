import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { DocumentDto } from '../../domain/Documents';
import { FinancialReportSection } from '../../domain/FinancialReport';
import { KpiToModifySelection, CalculatedKpiDto } from '../../domain/KPIs';
import { GetRiskParametersByIdResponse, RiskParameterDto } from '../../domain/RiskParameter';
import { RiskProfileDto } from '../../domain/RiskProfile';
import GetFspInformationResponse from '../../domain/services/fspControllerServices/response/GetFspInformationResponse';
import { GetCalculatedKpisByRiskProfileIdResponse } from '../../domain/services/Kpi/KpiControllerServices/response';
import { RiskParameterNotFoundReason, RiskProfileStatus } from '../../enums/RiskProfile';

export interface RiskProfileState {
  /**
   * Current Risk Profile.
   */
  currentRiskProfile?: RiskProfileDto
  /**
   * List of all RISC parameters.
   */
  currentRiskParameters?: RiskParameterDto[]
  /**
   * Reason why something was not found in Risk Parameters
   */
  currentRiskParameterNotFoundReason?: RiskParameterNotFoundReason
  /**
   * Sections of the financial report
   */
  currentFinancialReport?: FinancialReportSection[]
  /**
   * List of all Kpis related to current RiskProfile
   */
  currentCalculatedKpis?: CalculatedKpiDto[]
  /**
   * List of all Documents related to current RiskProfile
   */
  currentDocuments?: DocumentDto[]
  /**
   * Validation of FSP to know if the KPIs were fulfilled.
   */
  currentKpisFulfilled?: boolean
  /**
   * KPI to modify.
   */
  kpiToModifyId?: number
  /**
   * Location to return of KPI
   */
  kpiYCoordinate?: number
  /**
   * Fsp Information
   */
  fspInformation?: GetFspInformationResponse
}

const initialState: RiskProfileState = {
  currentRiskProfile: undefined,
  currentRiskParameters: undefined,
  currentRiskParameterNotFoundReason: undefined,
  currentFinancialReport: undefined,
  currentCalculatedKpis: undefined,
  currentDocuments: undefined,
  currentKpisFulfilled: undefined,
  kpiToModifyId: undefined,
  kpiYCoordinate: undefined,
  fspInformation: undefined,
};

export const riskProfileSlice = createSlice({
  name: 'riskProfile',
  initialState,
  reducers: {
    resetCurrentRiskProfileState: (state) => {
      state.currentRiskProfile = undefined;
      state.currentRiskParameters = undefined;
      state.currentFinancialReport = undefined;
      state.currentCalculatedKpis = undefined;
      state.currentDocuments = undefined;
      state.currentKpisFulfilled = undefined;
    },
    setCurrentCalculatedKpis: (state, action: PayloadAction<GetCalculatedKpisByRiskProfileIdResponse | undefined>) => {
      if (action.payload == null) {
        return;
      }

      const { kpis, kpisFulfilled } = action.payload;
      state.currentCalculatedKpis = kpis;
      state.currentKpisFulfilled = kpisFulfilled;
    },
    setCurrentRiskProfile: (state, action: PayloadAction<RiskProfileDto | undefined>) => {
      state.currentRiskProfile = action.payload;
    },
    setCurrentFinancialReport: (state, action: PayloadAction<FinancialReportSection[] | undefined>) => {
      state.currentFinancialReport = action.payload;
    },
    setCurrentRiskParameters: (state, action: PayloadAction<GetRiskParametersByIdResponse | undefined>) => {
      state.currentRiskParameters = action.payload?.riskParameters;
      state.currentRiskParameterNotFoundReason = action.payload?.notFoundReason;
    },
    setFspInformation: (state, action: PayloadAction<GetFspInformationResponse | undefined>) => {
      state.fspInformation = action.payload;
    },
    setKpiToModify: (state, action: PayloadAction<KpiToModifySelection | undefined>) => {
      if (action.payload == null) {
        state.kpiToModifyId = action.payload;
        return;
      }

      const { id, coordinate } = action.payload;
      state.kpiToModifyId = id;
      state.kpiYCoordinate = coordinate;
    },
    clearKpiYCoordinates: (state) => {
      state.kpiYCoordinate = undefined;
    },
    updateRiskProfilesDocuments: (state, action: PayloadAction<DocumentDto[]>) => {
      if (state.currentDocuments == null) {
        state.currentDocuments = action.payload;
        return;
      }

      const uniqueArray = _.uniqBy(state.currentDocuments.concat(action.payload), 'id');

      state.currentDocuments = uniqueArray;
    },
    updateRiskProfileStatus: (state, action: PayloadAction<RiskProfileStatus>) => {
      const copyRiskProfile = state.currentRiskProfile;
      if (copyRiskProfile == null) {
        return;
      }
      copyRiskProfile.status = action.payload;

      state.currentRiskProfile = copyRiskProfile;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  resetCurrentRiskProfileState,
  setCurrentCalculatedKpis,
  setCurrentRiskProfile,
  setCurrentFinancialReport,
  setCurrentRiskParameters,
  setFspInformation,
  setKpiToModify,
  clearKpiYCoordinates,
  updateRiskProfilesDocuments,
  updateRiskProfileStatus,
} = riskProfileSlice.actions;

export default riskProfileSlice.reducer;
