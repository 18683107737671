import React from 'react';
import ReactDOM from 'react-dom/client';
import './windowConfiguration';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Application } from './application';
import reportWebVitals from './reportWebVitals';
import './i18n/i18n';
import { store } from './redux';
// using Roboto Font from matieral ui
// https://mui.com/material-ui/react-typography/#general
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { waitForClientAuthentication, removeAuthParams } from './services/CompeonApi/Client';

function render(): void {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  );
  // TODO: Insert React.StrictMode again after testing
  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <Application />
      </BrowserRouter>
    </Provider>,
  );
}

waitForClientAuthentication
  .then(removeAuthParams)
  .then(render);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
