import { ThemeOptions } from '@mui/material';

const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#00283E',
    },
    secondary: {
      main: '#EBFAFD',
    },
    header: {
      main: '#ffffff',
    },
  },
  compeonLogo: {
    color: '#00283e',
  },
  calendarBox: {
    color: '#EBFAFD',
    colorChangeRule: 'darken',
  },
};

export default themeOptions;
