import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type KpiPageSettings = {
  generateStructureAfterCalculationSaving: boolean
}

export interface SettingsState {
  kpiPage: KpiPageSettings
}

const initialState: SettingsState = {
  kpiPage: {
    generateStructureAfterCalculationSaving: true,
  },
};

export const settingsSlice = createSlice({
  name: 'options',
  initialState,
  reducers: {
    // #region kpi page settings
    setGenerateStructureAfterCalculationSaving: (state, action: PayloadAction<boolean>) => {
      state.kpiPage.generateStructureAfterCalculationSaving = action.payload;
    },
    // #endregion kpi page settings
  },
});

export const {
  setGenerateStructureAfterCalculationSaving,
} = settingsSlice.actions;

export default settingsSlice.reducer;
