const riskProfileId = ':riskProfileId';
const kpiId = ':kpiId';

const RoutesConstants = {
  routePath: {
    startPage: '/',
    riskProfilePage: `/riskProfilePage/${riskProfileId}`,
    kpisManagerPage: '/kpisManagerPage',
    testPage: '/testPage',
    notFoundPage: '*',
    kpiPage: `/kpi/${kpiId}`,
  },
};

export default RoutesConstants;

export function getRiskProfilePageUrlWithParameter(guid : string) : string {
  return RoutesConstants.routePath.riskProfilePage.replace(riskProfileId, guid);
}

export function getKpiPageUrlWithParameter(id : number) : string {
  return RoutesConstants.routePath.kpiPage.replace(kpiId, id.toString());
}
