import { ApiError } from '../domain/Api';
import { isApiCallError } from '../domain/Api/ApiError';
import { GetFspInformationResponse } from '../domain/services/fspControllerServices/response';
import { ApiService } from './ApiService';

export class FspInfoControllerService extends ApiService {
  async getFspInformation(): Promise<GetFspInformationResponse | ApiError> {
    try {
      const response = await this.Get<GetFspInformationResponse>(
        'FspInfo/GetFspInformation',
      );

      if (isApiCallError(response)) return response;

      return response;
    } catch (error) {
      return error as ApiError;
    }
  }
}

export const fspInfoControllerService = new FspInfoControllerService();
