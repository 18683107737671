import React, { lazy, Suspense } from 'react';
import { PathRouteProps } from 'react-router-dom';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SearchIcon from '@mui/icons-material/Search';
import DashboardIcon from '@mui/icons-material/Dashboard';
import RoutesConstants, { getRiskProfilePageUrlWithParameter } from '../constants/RoutesConstants';
import { SideMenuEntry } from '../domain/application';

const StartPage = lazy(() => import('./StartPage/StartPage'));
const RiskProfilePage = lazy(() => import('./RiskProfile/RiskProfilePage'));
const KpisManagerPage = lazy(() => import('./KpisManager/KpisManagerPage'));
const TestPage = lazy(() => import('./Test/TestPage'));
const NotFoundPage = lazy(() => import('./NotFound/NotFoundPage'));
const KpiPage = lazy(() => import('./KpiPage/KpiPage'));

export function getRouterRoutes(): PathRouteProps[] {
  const routes: Array<PathRouteProps> = [
    {
      path: `${RoutesConstants.routePath.startPage}`,
      element: (
        <Suspense fallback={<>...</>}>
          <StartPage />
        </Suspense>
      ),
    },
    {
      path: `${RoutesConstants.routePath.riskProfilePage}`,
      element: (
        <Suspense fallback={<>...</>}>
          <RiskProfilePage />
        </Suspense>
      ),
    },
    {
      path: `${RoutesConstants.routePath.kpisManagerPage}`,
      element: (
        <Suspense fallback={<>...</>}>
          <KpisManagerPage />
        </Suspense>
      ),
    },
    {
      path: `${RoutesConstants.routePath.testPage}`,
      element: (
        <Suspense fallback={<>...</>}>
          <TestPage />
        </Suspense>
      ),
    },
    {
      path: `${RoutesConstants.routePath.kpiPage}`,
      element: (
        <Suspense fallback={<>...</>}>
          <KpiPage />
        </Suspense>
      ),
    },
    // make sure that not found page is the last one, else it will be shown
    {
      element: (
        <Suspense fallback={<>...</>}>
          <NotFoundPage />
        </Suspense>
      ),
      path: RoutesConstants.routePath.notFoundPage,
    },
  ];
  return routes;
}

export function getSideMenuEntries(riskProfileId?: string): SideMenuEntry[] {
  const sideMenuEntries: SideMenuEntry[] = [];
  sideMenuEntries.push({
    id: 1,
    icon: <DashboardIcon />,
    titleResourcekey: 'Start Page',
    link: RoutesConstants.routePath.startPage,
  });
  sideMenuEntries.push({
    id: 2,
    icon: <QueryStatsIcon />,
    titleResourcekey: 'KPIs-Manager',
    link: RoutesConstants.routePath.kpisManagerPage,
  });

  if (riskProfileId != null) {
    sideMenuEntries.push({
      id: 3,
      icon: <SearchIcon />,
      titleResourcekey: 'Aktuelles RiskProfile',
      link: getRiskProfilePageUrlWithParameter(riskProfileId),
    });
  }

  return sideMenuEntries;
}
