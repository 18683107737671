import { ThemeOptions } from '@mui/material';

const themeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#666666',
    },
    secondary: {
      main: '#333333',
    },
    header: {
      main: '#000000',
    },
  },
  compeonLogo: {
    color: '#ffffff',
  },
  calendarBox: {
    color: '#101010',
    colorChangeRule: 'lighten',
  },
};

export default themeOptions;
