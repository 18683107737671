import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FspDto, FspProductDto } from '../../domain/Fsp';

export interface fspInformationState {
  /**
   * Fsp Information
   */
  fsp?: FspDto
  /**
   * Fsp Products
   */
  fspProducts?: FspProductDto[]
  /**
   * is Fsp Information loaded
   */
  lastLoadedFspDate?: Date
}

const initialState: fspInformationState = {
  fsp: undefined,
  fspProducts: undefined,
  lastLoadedFspDate: undefined,
};

export const fspInformationSlice = createSlice({
  name: 'fspInformation',
  initialState,
  reducers: {
    setFspResult: (state, action: PayloadAction<FspDto | undefined>) => {
      state.fsp = action.payload;
    },
    setFspProductsResult: (state, action: PayloadAction<FspProductDto[] | undefined>) => {
      state.fspProducts = action.payload;
    },
    setLastLoadedFspDate: (state, action: PayloadAction<Date | undefined>) => {
      state.lastLoadedFspDate = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setFspResult,
  setFspProductsResult,
  setLastLoadedFspDate,
} = fspInformationSlice.actions;

export default fspInformationSlice.reducer;
