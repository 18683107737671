import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { Route, RouteProps, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  DrawerHeader,
  Header, Main, SettingsDrawer, SideMenuDrawer,
} from './components';
import Footer from './components/Footer';
import { getRouterRoutes } from '../pages/routes';
import { getTheme } from '../themes';
import { Settings } from '../domain/application';
import { fspInfoControllerService } from '../services/FspInfoControllerService';
import { isApiCallError } from '../domain/Api/ApiError';
import { setFspInformation } from '../redux/slices/riskProfileSlice';
import { RootState } from '../redux';
import { setFspProductsResult, setFspResult, setLastLoadedFspDate } from '../redux/slices/fspInformationSlice';

const App = (): JSX.Element => {
  const [settingsOpen, setSettingsOpen] = useState<boolean>(false);
  const [settings, setSettings] = useState<Settings>({
    darkMode: false,
  });

  const [sideMenuOpen, setSideMenuOpen] = useState<boolean>(false);

  const dispatch = useDispatch();

  const lastLoadedFspDate = useSelector((state: RootState) => state.fspInformation.lastLoadedFspDate);

  const loadFspInformation = async () : Promise<void> => {
    const getFspInformation = await fspInfoControllerService.getFspInformation();
    if (getFspInformation == null) {
      return;
    }
    if (isApiCallError(getFspInformation)) {
      return;
    }
    dispatch(setFspInformation(getFspInformation));
    dispatch(setFspResult(getFspInformation.fsp));
    dispatch(setFspProductsResult(getFspInformation.fspProducts));
    dispatch(setLastLoadedFspDate(new Date()));
  };

  /**
   * To get FSP
   */
  useEffect(() => {
    // If it was alredy loaded, no need to load again.
    if (lastLoadedFspDate != null) {
      return;
    }
    if (!lastLoadedFspDate) {
      loadFspInformation();
    }
  }, []);

  const theme = getTheme(settings.darkMode);
  const routes = getRouterRoutes();

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{
        display: 'flex', minHeight: '100vh',
      }}
      >
        <CssBaseline />
        <Header
          onSettingsOpened={() => setSettingsOpen(true)}
          sideMenuOpen={sideMenuOpen}
          onSideMenuOpened={() => setSideMenuOpen(true)}
        />
        <SideMenuDrawer
          onSideMenuClose={() => setSideMenuOpen(false)}
          sideMenuOpen={sideMenuOpen}
          theme={theme}
        />
        <SettingsDrawer
          onSettingsClose={() => setSettingsOpen(false)}
          settingsOpen={settingsOpen}
          settings={settings}
          onSettingsChange={(changedSettings) => setSettings(changedSettings)}
        />
        <Main open={sideMenuOpen}>
          <Box sx={{
            height: '100%', position: 'relative',
          }}
          >
            <DrawerHeader />
            <Routes>
              {
              routes.map((route: RouteProps): (React.ReactElement | undefined) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={route.element}
                />
              ))
            }
            </Routes>
            <Footer />
          </Box>

        </Main>
      </Box>
    </ThemeProvider>
  );
};

export default App;
