import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RiskProfileDayGroupsDto, RiskProfileDto } from '../../domain/RiskProfile';
import { GetRiskProfilesByMpCompanyIdResponse, GetRiskProfilesByReferenceTypeIdResponse } from '../../domain/services/riskProfileControllerServices/response';
import { SelectedCompany, SelectedDay } from '../../domain/StartPage';

export interface StartPageState {
  /**
  * Grouped riskprofiles by day during the actual month.
  */
  monthRiskProfiles?: RiskProfileDayGroupsDto[]
  /**
   * If is DayJs, means the groupedDay for the selected day was null.
   * Therefore, we use DayJs to get the alterntive Date to show in the tab.
  */
  selectedDay?: SelectedDay
  /**
  * All RiskProfiles in a selected day from the Calendar.
  */
  selectedDayRiskProfiles?: RiskProfileDto[]
  /**
   * What is the maximum quantity in the month
   */
  biggestRiskProfileQuantity?: number,
  /**
   * Risk Search Profile search.
   */
  riskProfileSearch?: RiskProfileDto[],
  /**
  * Offset for LastUsed
  */
  riskProfileSearchOffSet: number,
  /**
   * Total amount of RiskPRofiles when Search Result is called.
   */
  totalSearchRiskProfiles?: number,
  /**
   * User last used profiles.
   */
  lastUsedProfiles?: RiskProfileDto[],
  /**
   * Offset for LastUsed
   */
  lastUsedOffSet: number,
  /**
   * Total amout of RiskProfiles when last used is called.
   */
  totalLastUsedRiskProfiles?: number
  /**
   * Company that user selects to find, the text will appear at the SearchBar.
   */
  currentCompany?: SelectedCompany
  /**
   * Loading when selecting a day
   */
  loadingDayRiskProfiles: boolean
  /**
  * Loading when searching a risk profile
  */
  loadingRiskProfileSearch: boolean
  /**
   * Standard count for FileTables. a.K.a row of tables.
   */
  countForTables: number
  /**
   * Current page when use the search for RiskProfiles
   */
  currentPageSearchRiskProfiles: number,
  /**
  * Current page of LastUsed RiskProfiles
  */
  currentPageLastUsedRiskProfiles: number
}

const initialState: StartPageState = {
  monthRiskProfiles: undefined,
  selectedDay: undefined,
  selectedDayRiskProfiles: undefined,
  biggestRiskProfileQuantity: undefined,
  riskProfileSearch: undefined,
  riskProfileSearchOffSet: 0,
  totalSearchRiskProfiles: undefined,
  lastUsedProfiles: undefined,
  lastUsedOffSet: 0,
  totalLastUsedRiskProfiles: undefined,
  currentCompany: undefined,
  loadingDayRiskProfiles: false,
  loadingRiskProfileSearch: false,
  countForTables: 10,
  currentPageLastUsedRiskProfiles: 1,
  currentPageSearchRiskProfiles: 1,
};

export const startPageSlice = createSlice({
  name: 'riskProfile',
  initialState,
  reducers: {
    selectDayFromCalendar: (state, action: PayloadAction<SelectedDay | undefined>) => {
      // Is null, just reset the parameters
      if (action.payload == null) {
        state.selectedDay = undefined;
        state.selectedDayRiskProfiles = undefined;
        return;
      }
      // It will have a Date from our DTO or by default DayJs
      state.selectedDay = action.payload;
    },
    updateSelectedDayRiskProfiles: (state, action: PayloadAction<RiskProfileDto[] | undefined>) => {
      if (action.payload == null) {
        state.selectedDayRiskProfiles = undefined;
        return;
      }
      state.selectedDayRiskProfiles = action.payload;
    },
    updateMonthRiskProfiles: (state, action: PayloadAction<RiskProfileDayGroupsDto[] | undefined>) => {
      if (action.payload == null) {
        state.monthRiskProfiles = undefined;
        state.biggestRiskProfileQuantity = undefined;
        return;
      }

      const quantityArray = action.payload.map((item) => item.riskProfileIds.length);
      const maxQuantity = Math.max(...quantityArray);
      if (state.monthRiskProfiles == null) {
        state.monthRiskProfiles = action.payload;
        state.biggestRiskProfileQuantity = maxQuantity;
        return;
      }

      // const uniqueArray = _.uniqBy(state.monthRiskProfiles.concat(action.payload), 'id');
      state.biggestRiskProfileQuantity = maxQuantity;
      state.monthRiskProfiles = action.payload;
    },
    updateRiskProfileSearchResult: (state, action: PayloadAction<RiskProfileDto>) => {
      const updatedArray = state.riskProfileSearch?.concat(action.payload);
      state.riskProfileSearch = updatedArray;
    },
    setRiskProfileSearchResult: (state, action: PayloadAction<GetRiskProfilesByReferenceTypeIdResponse | undefined>) => {
      if (action.payload == null) {
        state.riskProfileSearch = undefined;
        return;
      }
      const { totalRiskProfiles, currentPageRiskProfiles } = action.payload;

      state.riskProfileSearch = currentPageRiskProfiles;
      state.totalSearchRiskProfiles = totalRiskProfiles;
    },
    setLastUsedProfiles: (state, action: PayloadAction<GetRiskProfilesByMpCompanyIdResponse | undefined>) => {
      if (action.payload == null) {
        state.lastUsedProfiles = undefined;
        return;
      }
      const { currentPageRiskProfiles, totalRiskProfiles } = action.payload;

      state.lastUsedProfiles = currentPageRiskProfiles;
      state.totalLastUsedRiskProfiles = totalRiskProfiles;
    },
    setCurrentCompany: (state, action: PayloadAction<SelectedCompany | undefined>) => {
      state.currentCompany = action.payload;
    },
    setLoadingDayRiskProfiles: (state, action: PayloadAction<boolean>) => {
      state.loadingDayRiskProfiles = action.payload;
    },
    setLoadingRiskProfileSearch: (state, action: PayloadAction<boolean>) => {
      state.loadingRiskProfileSearch = action.payload;
    },
    setLastUsedOffSet: (state, action: PayloadAction<number>) => {
      state.lastUsedOffSet = action.payload;
    },
    setRiskProfileSearchOffSet: (state, action: PayloadAction<number>) => {
      state.riskProfileSearchOffSet = action.payload;
    },
    changeCurrentPageSearchRiskProfile: (state, action: PayloadAction<number>) => {
      state.currentPageSearchRiskProfiles = action.payload;
    },
    changeCurrentPageLastUsedRiskProfile: (state, action: PayloadAction<number>) => {
      state.currentPageLastUsedRiskProfiles = action.payload;
    },
    changeCountForTables: (state, action: PayloadAction<number>) => {
      state.countForTables = action.payload;
    },
    resetTablesToStartingPoint: (state) => {
      state.lastUsedProfiles = undefined;
      state.riskProfileSearch = undefined;
      state.lastUsedOffSet = 0;
      state.riskProfileSearchOffSet = 0;
      state.currentPageLastUsedRiskProfiles = 1;
      state.currentPageSearchRiskProfiles = 1;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  selectDayFromCalendar,
  updateSelectedDayRiskProfiles,
  updateMonthRiskProfiles,
  updateRiskProfileSearchResult,
  setRiskProfileSearchResult,
  setLastUsedProfiles,
  setCurrentCompany,
  setLoadingDayRiskProfiles,
  setLoadingRiskProfileSearch,
  setLastUsedOffSet,
  setRiskProfileSearchOffSet,
  changeCurrentPageLastUsedRiskProfile,
  changeCurrentPageSearchRiskProfile,
  changeCountForTables,
  resetTablesToStartingPoint,
} = startPageSlice.actions;

export default startPageSlice.reducer;
