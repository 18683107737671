import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GeneralInformationDto, GeneralInformationState, KpiCalculationEditDto, KpiCalculationEditState, KpiCalculationState,
  KpiCalculationViewDto, KpiDisplayRowDto, KpiEditFormularElementDto, KpiEditMainData, KpiStructureEditState, KpiStructureValidationState, SearchKpiVariableDto, StructureData,
  StructureState, ValidateKpiCalculationEditDataResultDto,
} from '../../domain/KPIs';
import { KpiPageError, KpiStructureEditStateMode } from '../../enums/Kpi';

export interface KpiPageState {
  /**
   * Main data for current kpi
   */
  kpiMainData?: KpiEditMainData,
  /**
   * Error object used for errors, which concerning whole page like kpi could not be found
   */
  kpiError?: KpiPageError | undefined,
  /**
   * Indicates whether main data for kpi page is loading
   */
  isKpiPageLoading: boolean,
  /**
   * State for section general information
   */
  generalInformationState: GeneralInformationState,
  /**
   * State for section calculation
   */
  calculationState: KpiCalculationState,
  /**
   * State for section structure
   */
  structureState: StructureState
}

const initialState: KpiPageState = {
  kpiMainData: undefined,
  kpiError: undefined,
  isKpiPageLoading: false,
  generalInformationState: {
    editData: undefined,
    isEditing: false,
    isLoading: false,
    originalData: undefined,
  },
  calculationState: {
    isEditing: false,
    isLoading: false,
    editData: undefined,
    viewData: undefined,
  },
  structureState: {
    isEditing: false,
    isLoading: false,
    editData: undefined,
    originalData: undefined,
    errorResourceKey: undefined,
  },
};

export const kpiPageSlice = createSlice({
  name: 'kpiPage',
  initialState,
  reducers: {
    // #region kpi page main content
    setKpiMainData: (state, action: PayloadAction<KpiEditMainData | undefined>) => {
      state.kpiMainData = action.payload;
    },
    setKpiError: (state, action: PayloadAction<KpiPageError | undefined>) => {
      state.kpiError = action.payload;
    },
    setIsKpiPageLoading: (state, action: PayloadAction<boolean>) => {
      state.isKpiPageLoading = action.payload;
    },
    // #endregion kpi page main content
    // #region general view
    /**
     * Handle closing edit mode with two cases
     * 1. user clicked save, then we get updated dto
     * 2. user clicked cancel, then we get undefined and reset to original data
     * @param state state before execution
     * @param action containing updated dto or undefined if canceled
     */
    closeGeneralViewEdit: (state, action: PayloadAction<GeneralInformationDto | undefined>) => {
      // reset edit data in both cases
      state.generalInformationState.editData = undefined;

      if (action.payload) {
        state.generalInformationState.originalData = action.payload;
      }

      state.generalInformationState.isEditing = false;
    },
    openGeneralViewEdit: (state, action: PayloadAction<GeneralInformationDto>) => {
      state.generalInformationState.editData = {
        description: action.payload.description ?? '',
        name: action.payload.name ?? '',
      };
      state.generalInformationState.isEditing = true;
    },
    setIsGeneralViewIsLoading: (state, action: PayloadAction<boolean>) => {
      state.generalInformationState.isLoading = action.payload;
    },
    updateGeneralViewEditData: (state, action: PayloadAction<GeneralInformationDto | undefined>) => {
      state.generalInformationState.editData = action.payload;
    },
    setGeneralViewOriginalData: (state, action: PayloadAction<GeneralInformationDto | undefined>) => {
      state.generalInformationState.originalData = action.payload;
    },
    // #endregion general view

    // #region calculation view
    closeCalculationViewEdit: (state) => {
      state.calculationState.editData = undefined;
      state.calculationState.isEditing = false;
    },
    openCalculationViewEdit: (state) => {
      state.calculationState.isEditing = true;
    },
    setIsCalculationViewLoading: (state, action: PayloadAction<boolean>) => {
      state.calculationState.isLoading = action.payload;
    },
    setCalculationViewViewData: (state, action: PayloadAction<KpiCalculationViewDto | undefined>) => {
      state.calculationState.viewData = action.payload;
    },
    initiateCalculationViewEditData: (state, action: PayloadAction<KpiCalculationEditDto | undefined>) => {
      if (action.payload) {
        state.calculationState.editData = {
          formularElements: action.payload.formularElements,
          hasUnknownElements: action.payload.hasUnknownElements,
          validationState: undefined,
          lastEditByValidation: false,
        };
      } else {
        state.calculationState.editData = undefined;
      }
    },
    setCalculationViewEditData: (state, action: PayloadAction<KpiCalculationEditState | undefined>) => {
      state.calculationState.editData = action.payload;
    },
    addVariableToCalculationViewEditData: (state, action: PayloadAction<SearchKpiVariableDto>) => {
      if (!state.calculationState.editData) return;

      state.calculationState.editData.formularElements.push(action.payload);
      state.calculationState.editData.lastEditByValidation = false;
    },
    setCalculationViewEditDataValidationStateLoading: (state, action: PayloadAction<{ loading: boolean, lastEditByValidation: boolean }>) => {
      if (!state.calculationState.editData?.validationState) return;

      state.calculationState.editData.lastEditByValidation = action.payload.lastEditByValidation;
      state.calculationState.editData.validationState.isLoading = action.payload.loading;
    },
    updateCalculationViewEditDataByValidationResult: (state, action: PayloadAction<ValidateKpiCalculationEditDataResultDto>) => {
      if (!state.calculationState.editData) return;

      // update information of formular elements
      state.calculationState.editData.formularElements = state.calculationState.editData.formularElements.map((element: KpiEditFormularElementDto) => {
        const validatedElement = action.payload.validatedFormularElements.find((validated: KpiEditFormularElementDto) => element.identifier === validated.identifier);
        // if it has not a validated element, then return old element
        if (!validatedElement) return element;

        // else update element
        return {
          ...element,
          isUnknown: validatedElement.isUnknown,
        };
      });
      state.calculationState.editData.hasUnknownElements = action.payload.validatedFormularElements.some((element: KpiEditFormularElementDto) => element.isUnknown);
      if (!state.calculationState.editData.validationState) {
        state.calculationState.editData.validationState = {
          isLoading: false,
        };
      }
      state.calculationState.editData.validationState.result = action.payload;
      state.calculationState.editData.lastEditByValidation = true;
    },
    updateCalculationViewEditDataBySavingResult: (state, action: PayloadAction<ValidateKpiCalculationEditDataResultDto>) => {
      if (!state.calculationState.editData) return;

      state.calculationState.editData.formularElements = action.payload.validatedFormularElements;
      state.calculationState.editData.hasUnknownElements = action.payload.validatedFormularElements.some((element: KpiEditFormularElementDto) => element.isUnknown);
      state.calculationState.editData.lastEditByValidation = false;
    },
    // #endregion calculation view

    // #region structure view
    closeStructureViewEdit: (state) => {
      state.structureState.editData = undefined;
      state.structureState.isEditing = false;
    },
    openStructureViewEdit: (state, action: PayloadAction<KpiStructureEditState | undefined>) => {
      state.structureState.isEditing = true;
      state.structureState.editData = action.payload;
    },
    setIsStructureViewLoading: (state, action: PayloadAction<boolean>) => {
      state.structureState.isLoading = action.payload;
    },
    setStructureErrorResourceKey: (state, action: PayloadAction<string | undefined>) => {
      state.structureState.errorResourceKey = action.payload;
    },
    setStructureOriginalData: (state, action: PayloadAction<StructureData | undefined>) => {
      state.structureState.originalData = action.payload;
    },
    setStructureEditData: (state, action: PayloadAction<KpiStructureEditState | undefined>) => {
      state.structureState.editData = action.payload;
    },
    setStructureEditDataDisplayRows: (state, action: PayloadAction<KpiDisplayRowDto[]>) => {
      if (state.structureState.editData == null) return;
      state.structureState.editData.displayRows = action.payload;
    },
    setStructureEditDataValidationStateLoading: (state, action: PayloadAction<boolean>) => {
      if (!state.structureState.editData?.validationState) return;
      state.structureState.editData.validationState.isLoading = action.payload;
    },
    setStructureEditDataValidationState: (state, action: PayloadAction<KpiStructureValidationState>) => {
      if (!state.structureState.editData) return;
      state.structureState.editData.validationState = action.payload;
    },
    openEditingKpiDisplayRowConfiguration: (state, action: PayloadAction<KpiDisplayRowDto>) => {
      if (!state.structureState.editData) return;
      state.structureState.editData.configurationState.rowToEdit = action.payload;
      state.structureState.editData.mode = KpiStructureEditStateMode.Edit;
    },
    openAddingKpiDisplayRowConfiguration: (state) => {
      if (!state.structureState.editData) return;
      state.structureState.editData.configurationState.rowToEdit = {};
      state.structureState.editData.mode = KpiStructureEditStateMode.Add;
    },
    closeEditingKpiDisplayRowConfiguration: (state) => {
      if (!state.structureState.editData) return;
      state.structureState.editData.configurationState.rowToEdit = undefined;
      state.structureState.editData.mode = KpiStructureEditStateMode.Structure;
    },
    addRowFromEditingKpiDisplayRowConfiguration: (state, action: PayloadAction<KpiDisplayRowDto>) => {
      if (!state.structureState.editData) return;
      const newRow: KpiDisplayRowDto = {
        ...action.payload,
      };
      if (newRow.id == null) {
        newRow.id = Math.min(0, ...state.structureState.editData.displayRows.map((r: KpiDisplayRowDto) => r.id)) - 1;
      }

      // try to add new row before endresult
      const endresultIndex = state.structureState.editData.displayRows.findIndex((r: KpiDisplayRowDto) => r.isEndResult);

      if (endresultIndex < 0) {
        // not available
        if (state.structureState.editData.displayRows.length < 1) {
          state.structureState.editData.displayRows = [newRow];
        } else {
          state.structureState.editData.displayRows.push(newRow);
        }
      } else {
        state.structureState.editData.displayRows = [
          ...state.structureState.editData.displayRows.slice(0, endresultIndex),
          newRow,
          state.structureState.editData.displayRows[endresultIndex],
        ];
      }

      state.structureState.editData.configurationState.rowToEdit = undefined;
      state.structureState.editData.mode = KpiStructureEditStateMode.Structure;
    },
    editRowFromEditingKpiDisplayRowConfiguration: (state, action: PayloadAction<KpiDisplayRowDto>) => {
      if (!state.structureState.editData) return;

      const updatedRow = action.payload;
      const editRowIndex = state.structureState.editData.displayRows.findIndex((r: KpiDisplayRowDto) => r.id === updatedRow.id);

      if (editRowIndex < 0) return;

      state.structureState.editData.displayRows[editRowIndex] = updatedRow;
      state.structureState.editData.configurationState.rowToEdit = undefined;
      state.structureState.editData.mode = KpiStructureEditStateMode.Structure;
    },
    deleteAllRowsFromStructureViewEdit: (state) => {
      if (!state.structureState.editData) return;

      state.structureState.editData.displayRows = [];
    },
    // #endregion structure view
  },
});

export const {
  // #region kpi page main content
  setKpiMainData,
  setKpiError,
  setIsKpiPageLoading,
  // #endregion kpi page main content
  // #region general view
  closeGeneralViewEdit,
  openGeneralViewEdit,
  setIsGeneralViewIsLoading,
  updateGeneralViewEditData,
  setGeneralViewOriginalData,
  // #endregion general view
  // #region calculation view
  closeCalculationViewEdit,
  openCalculationViewEdit,
  setIsCalculationViewLoading,
  setCalculationViewViewData,
  initiateCalculationViewEditData,
  setCalculationViewEditData,
  addVariableToCalculationViewEditData,
  setCalculationViewEditDataValidationStateLoading,
  updateCalculationViewEditDataByValidationResult,
  updateCalculationViewEditDataBySavingResult,
  // #endregion calculation view
  // #region structure view
  closeStructureViewEdit,
  openStructureViewEdit,
  setIsStructureViewLoading,
  setStructureErrorResourceKey,
  setStructureOriginalData,
  setStructureEditData,
  setStructureEditDataDisplayRows,
  setStructureEditDataValidationStateLoading,
  setStructureEditDataValidationState,
  openEditingKpiDisplayRowConfiguration,
  openAddingKpiDisplayRowConfiguration,
  closeEditingKpiDisplayRowConfiguration,
  addRowFromEditingKpiDisplayRowConfiguration,
  editRowFromEditingKpiDisplayRowConfiguration,
  deleteAllRowsFromStructureViewEdit,
  // #endregion structure view
} = kpiPageSlice.actions;

export default kpiPageSlice.reducer;
