import axios, { AxiosResponse, CancelToken, Method } from 'axios';
import { ServiceName } from '../enums/Services';
import ApiError, { AxiosRequestRejectObj } from '../domain/Api/ApiError';
import { HttpStatusCodeEventType } from '../domain/Api';
import { getCorrelationIdIfBaseResponseDto } from '../domain/baseResponse/BaseResponseDto';
import { client } from './CompeonApi/Client';

/**
 * Regex for identify datestring
 */
const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d*)?Z?$/;

interface StringKeyValueType {
  [key: string]: unknown
}

function handleDates<Output>(body: Output): Output {
  if (body === null || body === undefined || typeof body !== 'object') { return body; }

  const adjustedBody = { ...body } as unknown as StringKeyValueType;

  const keys = Object.keys(adjustedBody);
  const keysLength = keys.length;

  for (let keysIterator = 0; keysIterator < keysLength; keysIterator += 1) {
    const key = keys[keysIterator];
    const value = adjustedBody[key];

    if (typeof value === 'string' && dateFormat.test(value)) {
      adjustedBody[key] = new Date(value);
    } else if (typeof value === 'object') { handleDates(value); }
  }
  return adjustedBody as unknown as Output;
}

/**
 * Currently deserialisation not working automatically for datestring to date type.
 * So use this method to loop through response and check if datestring and conform it to date.
 * TODO check if it would e better to use axios.interceptors.response.use(originalResponse => {}) and perform there the conversion
 * @param response
 * @returns
 */
function parseAxiosResponse<Output>(response: AxiosResponse<Output>): AxiosResponse<Output> {
  if (!response?.data) { return response; }
  const adjustedResponse = response;
  adjustedResponse.data = handleDates(response.data);

  return adjustedResponse;
}

function callEventMethodByAxiosResponse<Output>(
  response: AxiosResponse<Output>,
  eventElement: HttpStatusCodeEventType<Output>,
): void {
  const eventMethod = eventElement.getEventFunction(response.status);
  if (eventMethod) eventMethod(response);
}

export class ApiService {
  private apiService = ServiceName.Api;

  constructor(apiService?: ServiceName) {
    if (apiService) { this.apiService = apiService; }
  }

  public Get<Output>(
    domainType: string,
    cancelToken?: CancelToken,
    serviceName?: ServiceName,
    notFoundValue: Output | undefined = undefined,
  ): Promise<Output | ApiError> {
    return this.GetRequest<Output>(domainType, notFoundValue, cancelToken, serviceName);
  }

  protected GetRequest<Output>(
    domainTypeOrUrl: string,
    notFoundValue: Output | undefined,
    cancelToken?: CancelToken,
    serviceName?: ServiceName,
  ): Promise<Output | ApiError> {
    return new Promise(
      (
        resolve: (response: Output) => void,
        reject: (response: ApiError) => void,
      ) => {
        const eventElement: HttpStatusCodeEventType<Output> = new HttpStatusCodeEventType<Output>(
          (response: AxiosResponse<Output>) => {
            reject(
              new ApiError(
                `Http Status Code ${response.status}: ${JSON.stringify(response.data)}`,
                undefined,
                getCorrelationIdIfBaseResponseDto(response.data),
              ),
            );
          },
          {
            404: (response: AxiosResponse<Output>) => {
              if (notFoundValue !== undefined) {
                resolve(notFoundValue);
              } else {
                reject(new ApiError(`Not found - ${response.statusText}`));
              }
            },
          },
          [
            {
              start: 200,
              end: 300,
              eventFunction: (response: AxiosResponse<Output>) => {
                resolve(response.data);
              },
            },
          ],
        );

        const requestFailed = (error: ApiError): void => {
          reject(error);
        };

        ApiService.ApiGetEvent<Output>(
          domainTypeOrUrl,
          serviceName ?? this.apiService,
          eventElement,
          requestFailed,
          cancelToken,
        );
      },
    );
  }

  static ApiGetEvent<Output>(
    endpointUrl: string,
    serviceName: ServiceName,
    eventElement: HttpStatusCodeEventType<Output>,
    requestFailed: (error: ApiError) => void,
    cancelToken?: CancelToken,
    params: Record<string, unknown> | undefined = undefined,
    apiVersion = '1.0',
  ): void {
    ApiService.ApiCallEvent<unknown, Output>(
      endpointUrl,
      'GET',
      serviceName,
      eventElement,
      requestFailed,
      cancelToken,
      params,
      undefined,
      apiVersion,
    );
  }

  public Post<Output, Input>(
    domainType: string,
    postObject: Input,
    cancelToken?: CancelToken,
    serviceName?: ServiceName,
    notFoundValue: Output | undefined = undefined,
  ): Promise<Output | ApiError> {
    return this.PostRequest<Output, Input>(domainType, postObject, notFoundValue, cancelToken, serviceName);
  }

  protected PostRequest<Output, Input>(
    domainType: string,
    postObject: Input,
    notFoundValue: Output | undefined,
    cancelToken?: CancelToken,
    serviceName?: ServiceName,
  ): Promise<Output | ApiError> {
    return new Promise(
      (
        resolve: (response: Output) => void,
        reject: (response: ApiError) => void,
      ) => {
        if (!postObject) {
          reject(new ApiError('PostObject is not set'));
          return;
        }

        const eventElement: HttpStatusCodeEventType<Output> = new HttpStatusCodeEventType<Output>(
          (response: AxiosResponse<Output>) => {
            reject(
              new ApiError(
                `Http Status Code ${response.status}: ${JSON.stringify(response.data)}`,
                undefined,
                getCorrelationIdIfBaseResponseDto(response.data),
              ),
            );
          },
          {
            404: (response: AxiosResponse<Output>) => {
              if (notFoundValue) {
                resolve(notFoundValue);
              } else {
                reject(new ApiError(`Not found - ${response.statusText}`));
              }
            },
          },
          [
            {
              start: 200,
              end: 300,
              eventFunction: (response: AxiosResponse<Output>) => {
                resolve(response.data);
              },
            },
          ],
        );

        const requestFailed = (error: ApiError): void => {
          reject(error);
        };

        ApiService.ApiPostEvent(
          domainType,
          serviceName ?? this.apiService,
          postObject,
          eventElement,
          requestFailed,
          cancelToken,
        );
      },
    );
  }

  static ApiPostEvent<Output, Input>(
    endpointUrl: string,
    serviceName: ServiceName,
    bodyData: Input,
    eventElement: HttpStatusCodeEventType<Output>,
    requestFailed: (error: ApiError) => void,
    cancelToken?: CancelToken,
    params: Record<string, unknown> | undefined = undefined,
    apiVersion = '1.0',
  ): void {
    ApiService.ApiCallEvent<Input, Output>(
      endpointUrl,
      'POST',
      serviceName,
      eventElement,
      requestFailed,
      cancelToken,
      params,
      bodyData,
      apiVersion,
    );
  }

  public Patch<Output, Input>(
    domainType: string,
    patchObject: Input,
    cancelToken?: CancelToken,
    serviceName?: ServiceName,
    notFoundValue: Output | undefined = undefined,
  ): Promise<Output | ApiError> {
    return this.PatchRequest<Output, Input>(domainType, patchObject, notFoundValue, cancelToken, serviceName);
  }

  protected PatchRequest<Output, Input>(
    domainType: string,
    patchObject: Input,
    notFoundValue: Output | undefined,
    cancelToken?: CancelToken,
    serviceName?: ServiceName,
  ): Promise<Output | ApiError> {
    return new Promise(
      (
        resolve: (response: Output) => void,
        reject: (response: ApiError) => void,
      ) => {
        if (!patchObject) {
          reject(new ApiError('PatchObject is not set'));
          return;
        }

        const eventElement: HttpStatusCodeEventType<Output> = new HttpStatusCodeEventType<Output>(
          (response: AxiosResponse<Output>) => {
            reject(
              new ApiError(
                `Http Status Code ${response.status}: ${JSON.stringify(response.data)}`,
                undefined,
                getCorrelationIdIfBaseResponseDto(response.data),
              ),
            );
          },
          {
            404: (response: AxiosResponse<Output>) => {
              if (notFoundValue) {
                resolve(notFoundValue);
              } else {
                reject(new ApiError(`Not found - ${response.statusText}`));
              }
            },
          },
          [
            {
              start: 200,
              end: 300,
              eventFunction: (response: AxiosResponse<Output>) => {
                resolve(response.data);
              },
            },
          ],
        );

        const requestFailed = (error: ApiError): void => {
          reject(error);
        };

        ApiService.ApiPatchEvent(
          domainType,
          serviceName ?? this.apiService,
          patchObject,
          eventElement,
          requestFailed,
          cancelToken,
        );
      },
    );
  }

  static ApiPatchEvent<Output, Input>(
    endpointUrl: string,
    serviceName: ServiceName,
    bodyData: Input,
    eventElement: HttpStatusCodeEventType<Output>,
    requestFailed: (error: ApiError) => void,
    cancelToken?: CancelToken,
    params: Record<string, unknown> | undefined = undefined,
    apiVersion = '1.0',
    additionalHeaders: { [key: string]: string } = {},
  ): void {
    ApiService.ApiCallEvent<Input, Output>(
      endpointUrl,
      'PATCH',
      serviceName,
      eventElement,
      requestFailed,
      cancelToken,
      params,
      bodyData,
      apiVersion,
      additionalHeaders,
    );
  }

  public Delete<Output>(
    domainType: string,
    cancelToken?: CancelToken,
    serviceName?: ServiceName,
    notFoundValue: Output | undefined = undefined,
  ): Promise<Output | ApiError> {
    return this.DeleteRequest<Output>(domainType, notFoundValue, cancelToken, serviceName);
  }

  protected DeleteRequest<Output>(
    domainType: string,
    notFoundValue: Output | undefined,
    cancelToken?: CancelToken,
    serviceName?: ServiceName,
  ): Promise<Output | ApiError> {
    return new Promise(
      (
        resolve: (response: Output) => void,
        reject: (response: ApiError) => void,
      ) => {
        const eventElement: HttpStatusCodeEventType<Output> = new HttpStatusCodeEventType<Output>(
          (response: AxiosResponse<Output>) => {
            reject(
              new ApiError(
                `Http Status Code ${response.status}: ${JSON.stringify(response.data)}`,
                undefined,
                getCorrelationIdIfBaseResponseDto(response.data),
              ),
            );
          },
          {
            404: (response: AxiosResponse<Output>) => {
              if (notFoundValue) {
                resolve(notFoundValue);
              } else {
                reject(new ApiError(`Not found - ${response.statusText}`));
              }
            },
          },
          [
            {
              start: 200,
              end: 300,
              eventFunction: (response: AxiosResponse<Output>) => {
                resolve(response.data);
              },
            },
          ],
        );

        const requestFailed = (error: ApiError): void => {
          reject(error);
        };

        ApiService.ApiDeleteEvent(
          domainType,
          serviceName ?? this.apiService,
          eventElement,
          requestFailed,
          cancelToken,
        );
      },
    );
  }

  static ApiDeleteEvent<Input, Output>(
    endpointUrl: string,
    serviceName: ServiceName,
    eventElement: HttpStatusCodeEventType<Output>,
    requestFailed: (error: ApiError) => void,
    cancelToken?: CancelToken,
    params: Record<string, unknown> | undefined = undefined,
    apiVersion = '1.0',
  ): void {
    ApiService.ApiCallEvent<Input, Output>(
      endpointUrl,
      'DELETE',
      serviceName,
      eventElement,
      requestFailed,
      cancelToken,
      params,
      undefined,
      apiVersion,
    );
  }

  static ApiCallEvent<Input, Output>(
    endpointUrl: string,
    method: Method,
    serviceName: ServiceName,
    eventElement: HttpStatusCodeEventType<Output>,
    requestFailed: (error: ApiError) => void,
    cancelToken?: CancelToken,
    params: Record<string, unknown> | undefined = undefined,
    bodyData: Input | undefined = undefined,
    apiVersion = '1.0',
    additionalHeaders: { [key: string]: string } | undefined = undefined,
  ): void {
    const baseUrl: string = ApiService.GetServiceBaseUrl(serviceName);
    if (!baseUrl) {
      return;
    }
    const url: string = endpointUrl.startsWith('http')
      ? endpointUrl
      : baseUrl + (endpointUrl.startsWith('/') ? '' : '/') + endpointUrl;

    const headers = {
      ...ApiService.GetHeaders(apiVersion),
      ...additionalHeaders,
    };

    axios.request<Input, AxiosResponse<Output>>({
      url,
      headers,
      method,
      validateStatus: (): boolean => true,
      // validateStatus: (status: number): boolean => {
      // return status >= 200 && status < 300; // default
      params,
      data: bodyData,
      cancelToken,
    })
      .then((fulfilledResponse: AxiosResponse<Output>) => {
        const parsedFulfilledResponse = parseAxiosResponse<Output>(fulfilledResponse);
        callEventMethodByAxiosResponse(parsedFulfilledResponse, eventElement);
      })
      .catch((error: AxiosRequestRejectObj) => {
        // all http error codes are allowed, so error won't be AxiosResult
        requestFailed(new ApiError(undefined, error));
      });
  }

  static GetServiceBaseUrl(service: ServiceName): string {
    switch (service) {
      case ServiceName.Api:
        return window.REACT_APP_SERVICE_API;
      default:
        return '';
    }
  }

  static GetHeaders(apiVersion = '1.0'): { [key: string]: string } {
    return {
      'Content-Type': `application/json; v=${apiVersion}`,
      Authorization: client.tokenHeader,
    };
  }
}
