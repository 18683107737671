/**
 * Left navigation for KpiPage
 */
export enum SectionType {
  General = 'General',
  Calculation = 'Calculation',
  Validation = 'Validation',
  Structure = 'Structure'
}
/**
 * KpiTypes
 */
export enum KpiType {
  Kpi = 'kpi',
  Help = 'help'
}
/**
 * Enum for errors on kpi page
 */
export enum KpiPageError {
  ApiErrorWhileLoadingKpi = 'apiErrorWhileLoadingKpi',
  InvalidKpiId = 'invalidKpiId',
  KpiNotFoundById = 'kpiNotFoundById '
}

/**
 * Types of kpi variables
 */
export enum KpiVariableType {
  /**
   * Variable of a kpi
   */
  Kpi = 'kpi',
  /**
   * Variable of a kpi variable
   */
  KpiValue = 'kpiValue',
  /**
   * Variable of a kpi parameter
   */
  KpiParameter = 'kpiParameter',
}

/**
 * types of formular validation errors
 */
export enum FormularValidationErrorType {
  /**
   * Validated formular is empty, which is not allowed
   */
  EmptyFormular = 'emptyFormular',
  /**
   * Unknown exception while executing test calculation
   */
  CalculationExecution = 'calculationExecution',
  /**
   * Some calculation errors occurred while calculating formular
   */
  CalculationErrors = 'calculationErrors',
}

/**
 * types of kpi structure validation erros
 */
export enum KpiStructureValidationErrorType {
  /**
   * No rows were passed to validation, so its an empty structure, which is not allowed.
   */
  NoRowsDefined = 'noRowsDefined',
  /**
   * Kpi has no row for endresult
   */
  MissingEndResulRow = 'missingEndResulRow',
  /**
   * Kpi has more than one row for endresult
   */
  TooManyEndResulRows = 'tooManyEndResulRows',
}

/**
 * Defines type of error for a display row inside kpi structure
 */
export enum DisplayRowValidationErrorDtoType {
  /**
   * No kpi value is defined for display row, which is not an endresult
   */
  MissingKpiValue = 'missingKpiValue',
  /**
   * DisplayName is not set or empty
   */
  MissingDisplayName = 'missingDisplayName',
  /**
   * KpiValue for KpiValueId is not existing
   */
  NotExistingKpiValue = 'notExistingKpiValue'
}

/**
 * types of display row
 */
export enum KpiDisplayRowType {
  /**
   * Display row stores normal value
   */
  Value = 'value',
  /**
   * Display row stores end result
   */
  EndResult = 'endResult',
  /**
   * Display row stores inner result
   */
  InnerResult = 'innerResult',
}

/**
 * Modes of KpiStructureEditState whether view for:
 * - edit structure
 * - configuration for adding row
 * - configure for editting row
 */
export enum KpiStructureEditStateMode {
  /**
   * Mode to edit structure
   */
  Structure = 'structure',
  /**
   * Mode to edit row
   */
  Edit = 'edit',
  /**
   * Mode to add row
   */
  Add = 'add'
}
